import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Button } from "react-bootstrap";
import ReactDOM from "react-dom";
import { goPage } from "../components/functions";

function Languages() {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get("getlanguages").then((res) => setData(res.data));
  }, []);

  return (
    <div className="d-flex flex-column align-items-center justify-content-center background__home">
      <div className="disposition__flags">
        {data.map((el) => (
          <div key={el.Language.id}>
            <img
              onClick={() => goPage(`/audios?id=${el.Language.id}`)}
              className="image__flags"
              src={el.LanguageFlag.Name}
            />
          </div>
        ))}
      </div>
      <Container
        className="w-100 text-center mt-5"
        style={{ marginTop: "15px" }}
      >
        <span className=" text-center mb-2 px-4 py-2 text__language">
          Select a language
        </span>
      </Container>
    </div>
  );
}

ReactDOM.render(<Languages />, document.getElementById("language"));
